import { createFileRoute, Navigate } from "@tanstack/react-router";

export const Route = createFileRoute("/payment-redirect")({
  component: () => (
    <Navigate
      to="/"
      search={{
        modal: "profile",
        profileSection: "wallet",
      }}
      replace
    />
  ),
});
